@import 'variables';

.sidebar {
    width: 260px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 30px 15px;
    background-color: $white-color;
    box-shadow: 4px 0 4px 0 rgba(43, 45, 55, 0.01);

    .sidebar-body {
        position: sticky;
        top: 30px;
    }

    .sidebar-logo {
        width: 150px;
        margin: 2px 0 32px 15px;
        display: block;
    }

    .nav-pills {
        .nav-link {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 15px 0;
            border-radius: 8px;

            i {
                width: 56px;
                text-align: center;
                font-size: 20px;
            }

            .fa-chevron-down {
                width: auto;
                font-size: 14px;
                display: flex;
                margin-left: auto;
                transform-origin: center center;
            }

            &[aria-expanded=true] {
                .fa-chevron-down {
                    transform: rotate(180deg);
                }
            }

            &:not(.active) {
                i {
                    color: $primary-color;
                }

                &:hover {
                    color: $primary-color;
                    i:not(.fa-chevron-down) {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background: linear-gradient(325deg, #c13433 0%,#df4b4a 100%);
        font-weight: bold;
    }
}


.sidebar-footer {
    border-top: 1px solid #eee;
}

.nav-sub-item {
    padding-left: 56px;

    .nav-sub-link {
        display: block;
        margin: 6px 0;
        color: $grey-color-3;
        width: 100%;
        text-decoration: none;

        &.active {
            color: $primary-color;
            font-weight: bold;
        }

        &:hover {
            color: $primary-color;
        }
    }
}

.sidebar-menu {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    overflow-x: hidden;
}